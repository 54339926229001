.category-info {
	margin-bottom: 40px;

	&__title {
		font-size: 20px;
		font-weight: 500;
	}

	&__select-group {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			top: 50%;
			right: 18px;
			margin-top: (5px / 2 ) * -1;
			@include triangle('down', 6px, 5px, $black-two);
		}
	}

	&__select {
		color: $dark-slate-blue-two;
		background: $pale-grey-two;
		border: 1px solid $pale-grey-four;
		font-size: 12px;
		font-weight: 400;
		padding: 0;
		padding-left: 18px;
		padding-right: 18px + 6 + 15;
		height: 32px;
		border-radius: 32px;
		@include prefixer(appearance, none, 'webkit');

		&:after {
			content: 'a';
		}
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 30px;
	}
}

.category-list {
	&__item {
		position: relative;
		background: $pale-grey-two;
		margin-bottom: 30px;
		padding: 40px;
		color: $black-two;
		overflow: hidden;

		&:hover {
			.category-list__image {
				transform: translate(-50%, 0) scale(1.1);
			}
		}
	}

	&__head {
	}

	&__body {
		height: 380px;
	}

	&__foot {
		background: #fff;
		margin: 0 -20px;
		margin-bottom: -20px;
		padding: 0 20px;
		height: 55px;
	}

	&__title {
		font-size: 18px;
		font-weight: 500;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
	}

	&__sub-title {
		font-size: 14px;
		font-weight: 600;
		opacity: 0.3;
		margin-bottom: 5px;
	}

	&__image {
		transition: all ease 0.3s;
		transform: translate(-50%, 0);
		left: 50%;
		position: relative;
		max-width: 360px;
	}

	&__more-btn {
		font-size: 14px;
		font-weight: 400;
	}

	&__size {
		font-size: 17px;
		font-weight: 400;
	}

	@include media-breakpoint-down(md) {
		&__image {

		}
	}


}
