/*.product-gallery {
    &__item {
        display: block;
        width: 100%;
        text-align: center;
    }

    @include media-breakpoint-down(lg) {
        &__image {
            max-width: 100%;
        }
    }
}*/

.product-gallery {
    &__image {
        @include centerImage(100%, 255px);
        margin-bottom: 30px;
    }

    .flickity-prev-next-button {
        &.next {
            right: 30px;
        }

        &.previous {
            left: 30px;
        }
    }

    @include media-breakpoint-down(sm) {
        &__image {
            @include centerImage(100%, auto);
        }
    }
}

.product-image-list {
    margin-top: -280px;

    &__item {
        max-width: 100%;
        transform: translate(10%,0);

    }

    .flickity-page-dots {
        justify-content: flex-end;
    }

    @include media-breakpoint-down(md) {
        margin-top: 20px;
        width: 100%;
        padding-bottom: 60px;

        &__item {
            transform: none;
        }

        .flickity-page-dots {
            justify-content: center;
            bottom: 0;
        }
    }

    @include media-breakpoint-only(lg) {
        margin-top: -180px;
    }


}

.product-gallery-thumb {
    width: (40px + 10) * 3;

    &__item {
        width: 40px;
        height: 40px;
        margin-left: 10px;
        background: $pale-grey-four;

        &.is-selected {
            background: $twilight-blue;
        }
    }

    &__image {
        height: 100%;
    }
}

.product-block {
    margin: 100px 0;

    &__title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    &__description {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.6;
    }

    @include media-breakpoint-down(lg) {
        margin: 0 0 40px 0;

        &__image {
            max-width: 100%;
        }
    }
}

/*.product-properties {
    &__item {
        font-size: 12px;
        font-weight: 600;
        height: 70px;
        background: $pale-grey-two;
        padding: 30px;

        &:nth-child(2n+2) {
            background: #fff;
        }
    }

    &__label {
        opacity: 0.3;
    }

    &__value {
    }
}*/

.product-properties {
    background: $pale-grey-two;
    padding: ($grid-gutter-width * 2 - 10) 0;
    margin-top: $grid-gutter-width * 2;
    display: flex;
    align-items: center;
    justify-content: center;

    &__item {
        color: $dark-slate-blue-two;
        padding: 0 $grid-gutter-width;
    }

    &__item-label {
        font-size: 12px;
        font-weight: 400;
    }

    &__item-value {
        font-size: 30px;
        font-weight: 500;

        sup {
            font-size: 11px;
            font-weight: 700;
            top: -13px;
        }
    }

    @include media-breakpoint-down(sm) {
        padding: 0;
        flex-direction: column;
        .item {
            padding: $grid-gutter-width;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;

            .item-label {
                margin-left: 15px;
            }
        }
    }
}

.product-information {
    &__item {
        font-size: 13px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $pale-grey-four;
        min-height: 80px;
    }

    &__label {
        color: fade_out($dark-slate-blue-two, 0.5);
    }

    &__value {
        color: $dark-slate-blue-two;
    }
}

.product-zigzag {
    &__title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 40px;
    }

    &__description {
        font-size: 13px;
        line-height: 1.6;
        font-weight: 500;
    }

    &__image {
        width: 100%;
    }

    @include media-breakpoint-down(md) {
        &__image {
            max-width: 100%;
        }
    }
}
