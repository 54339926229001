
.modal-header {
    .close {
        padding: 15px;
        margin: -15px;
        font-size: 18px;
        opacity: 1;
    }
}

.modal-title {
    font-size: 18px;
    font-weight: 500;
}


