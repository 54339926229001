.nav-tabs {
	.nav-link {
		border: none;
		border-bottom: 3px solid transparent;
		font-size: 14px;
		font-weight: 500;
		color: fade_out($dark-slate-blue-two, 0.5);
		padding: 13px 10px;
		margin: 0 $grid-gutter-width;

		&.active {
			border-bottom-color: $twilight-blue;
			color: $dark-slate-blue-two;
		}
	}

	@include media-breakpoint-down(sm) {
		.nav-link {
			margin: 0 $grid-gutter-width / 2;
		}
	}
}