.box-group {
	margin: 100px 0;

	/* Container Element */
	&__head {
		margin-bottom: 50px;
	}

	&__body {
	}

	&__inner {
		position: relative;
	}


	/* Custom Element */
	&__title {
		font-size: 36px;
		font-weight: 500;
	}

	&__description {
		font-size: 13px;
		font-weight: 500;
		line-height: 1.6;
	}

	&__btn {
		min-width: 185px;
	}

	&__image {
	}

	@include media-breakpoint-down(md) {
		margin: 50px 0;

		&__image {
			max-width: 100%;
		}
	}


	/* Box Group Type */
	&--promo {
		position: relative;
		height: calc(100vh);
		min-height: 540px;
		background-color: $azul;
		margin-top: $header-height * -1;

		.flickity-page-dots {
			bottom: 25px;
			justify-content: center;
			
			.dot {
				background: none;
				border: 2px solid #fff;
				transition: all ease .3s;
				opacity: 1;
				
				&.is-selected {
					background: #fff;
				}
			}
		}

		@include media-breakpoint-down(md) {
			margin-left: 0;
			margin-right: 0;
			height: 100vh;
			margin-top: -70px;
		}

		@include media-breakpoint-only(md) {
			height: 80vh;
		}
	}

	&--properties {

	}

	&--catalog {
		.box-group__inner {
			padding: 60px 80px;
			overflow: hidden;
		}

		.box-group__image {
			position: absolute;
			right: 0;
			bottom: 0;
		}

		@include media-breakpoint-down(md) {
			.box-group__inner {
				padding: 30px;
				text-align: center;
			}

			.box-group__image {
				position: relative;
				margin-top: 30px;
				margin-bottom: -30px;
			}
		}
	}

}

.flickity-page-dots {
	display: flex;
	align-items: start;
	justify-content: flex-start;
	bottom: -50px;

	.dot {
		width: 8px;
		height: 8px;
        background: darken($pale-grey-two, 40%);
		margin: 0 4px;

		&.is-selected {
			background: $azul;
		}
	}

	@include media-breakpoint-down(md) {
		bottom: -25px + (30 / 2);
	}
}