body {
	&.homepage-body {
		.site-head {
			background: none;
		}
	}

	&.site-head-light {
		.site-head {
			box-shadow: none;
			background: $azul;
		}

		.head-logo {
			&__image {
				&--light {
					display: block;
				}

				&--dark {
					display: none;
				}
			}
		}

		.head-menu {
			&__link {
				color: #fff;

				&--active {
					border-color: #fff;
				}
			}

			@include media-breakpoint-down(md) {
				&__dropdown-link {
					color: #fff;
				}
			}
		}

		.head-contact {
			&__label, &__value {
				color: #fff;
			}
		}

		.head-cart {
			&__btn {
				color: #fff;
			}
		}

		.hamburger {
			&.is-active {
				.hamburger-inner {
					background: #fff;
					&:before, &:after {
						background: #fff;
					}
				}
			}

			.hamburger-inner {
				background: #fff;
				&:before, &:after {
					background: #fff;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		padding-top: 70px;
	}

	img {
		max-width: 100%;
	}
}

@import "header";
@import "footer";
@import "section";
@import "page";
@import "box-group";
@import "home";
@import "guide";
@import "category";
@import "product";
@import "contact";
@import "cart";
