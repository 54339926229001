// Bootstrap functions
//
// Utility mixins and functions for evaluating source code across our variables, maps, and mixins.

// Ascending
// Used to evaluate Sass maps like our grid breakpoints.
@mixin _assert-ascending($map, $map-name) {
	$prev-key: null;
	$prev-num: null;
	@each $key, $num in $map {
		@if $prev-num == null or unit($num) == "%" {
			// Do nothing
		} @else if not comparable($prev-num, $num) {
			@warn "Potentially invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} whose unit makes it incomparable to #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		} @else if $prev-num >= $num {
			@warn "Invalid value for #{$map-name}: This map must be in ascending order, but key '#{$key}' has value #{$num} which isn't greater than #{$prev-num}, the value of the previous key '#{$prev-key}' !";
		}
		$prev-key: $key;
		$prev-num: $num;
	}
}

// Starts at zero
// Used to ensure the min-width of the lowest breakpoint starts at 0.
@mixin _assert-starts-at-zero($map, $map-name: "$grid-breakpoints") {
	$values: map-values($map);
	$first-value: nth($values, 1);
	@if $first-value != 0 {
		@warn "First breakpoint in #{$map-name} must start at 0, but starts at #{$first-value}.";
	}
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @author Hugo Giraudel
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// Color contrast
@function color-yiq($color, $dark: $yiq-text-dark, $light: $yiq-text-light) {
	$r: red($color);
	$g: green($color);
	$b: blue($color);

	$yiq: (($r * 299) + ($g * 587) + ($b * 114)) / 1000;

	@if ($yiq >= $yiq-contrasted-threshold) {
		@return $dark;
	} @else {
		@return $light;
	}
}

// Retrieve color Sass maps
@function color($key: "blue") {
	@return map-get($colors, $key);
}

@function theme-color($key: "primary") {
	@return map-get($theme-colors, $key);
}

@function gray($key: "100") {
	@return map-get($grays, $key);
}

// Request a theme color level
@function theme-color-level($color-name: "primary", $level: 0) {
	$color: theme-color($color-name);
	$color-base: if($level > 0, $black, $white);
	$level: abs($level);

	@return mix($color-base, $color, $level * $theme-color-interval);
}

@function strip-units($number) {
	@if type-of($number) == 'number' and not unitless($number) {
		@return $number / ($number * 0 + 1);
	}

	@return $number;
}

@function rem($pxval) {
	@if not unitless($pxval) {
		$pxval: strip-units($pxval);
	}

	$base: 16;
	@if not unitless($base) {
		$base: strip-units($base);
	}
	@return ($pxval / $base) * 1rem;
}


@mixin getFonts($fontname) {
	$fontpath: "../fonts/#{$fontname}/";
	@font-face {
		font-family: '#{$fontname}-xlight';
		src: url('#{$fontpath}xlight.eot');
		src: url('#{$fontpath}xlight.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}xlight.woff') format('woff'),
		url('#{$fontpath}xlight.ttf') format('truetype'),
		url('#{$fontpath}xlight.otf') format('opentype'),
		url('#{$fontpath}xlight.svg##{$fontname}-light') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-light';
		src: url('#{$fontpath}light.eot');
		src: url('#{$fontpath}light.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}light.woff') format('woff'),
		url('#{$fontpath}light.ttf') format('truetype'),
		url('#{$fontpath}light.otf') format('opentype'),
		url('#{$fontpath}light.svg##{$fontname}-light') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-regular';
		src: url('#{$fontpath}regular.eot');
		src: url('#{$fontpath}regular.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}regular.woff') format('woff'),
		url('#{$fontpath}regular.ttf') format('truetype'),
		url('#{$fontpath}regular.otf') format('opentype'),
		url('#{$fontpath}regular.svg##{$fontname}-regular') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-medium';
		src: url('#{$fontpath}medium.eot');
		src: url('#{$fontpath}medium.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}medium.woff') format('woff'),
		url('#{$fontpath}medium.ttf') format('truetype'),
		url('#{$fontpath}medium.otf') format('opentype'),
		url('#{$fontpath}medium.svg##{$fontname}-medium') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-bold';
		src: url('#{$fontpath}bold.eot');
		src: url('#{$fontpath}bold.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}bold.woff') format('woff'),
		url('#{$fontpath}bold.ttf') format('truetype'),
		url('#{$fontpath}bold.otf') format('opentype'),
		url('#{$fontpath}bold.svg##{$fontname}-bold') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
	@font-face {
		font-family: '#{$fontname}-xbold';
		src: url('#{$fontpath}xbold.eot');
		src: url('#{$fontpath}xbold.eot?#iefix') format('embedded-opentype'),
		url('#{$fontpath}xbold.woff') format('woff'),
		url('#{$fontpath}xbold.ttf') format('truetype'),
		url('#{$fontpath}xbold.otf') format('opentype'),
		url('#{$fontpath}xbold.svg##{$fontname}-bold') format('svg');
		font-weight: 400;
		font-style: normal;
		font-stretch: normal;
	}
}

@mixin article() {
	@extend .clearfix;
	.paragraph {
		font-size: 14px;
		font-weight: 400;
		line-height: 1.8;
	}

	h1 {
		margin-bottom: #{(0.25 * 8)}rem;
	}
	h2 {
		margin-bottom: #{(0.25 * 7)}rem;
	}
	h3 {
		margin-bottom: #{(0.25 * 6)}rem;
	}
	h4 {
		margin-bottom: #{(0.25 * 5)}rem;
	}
	h5 {
		margin-bottom: #{(0.25 * 4)}rem;
	}
	h6 {
	}

	p {
		margin: 0 0 $paragraph-margin-bottom 0;
		@extend .paragraph;
	}

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		margin-bottom: $paragraph-margin-bottom;

		li {
			@extend .paragraph;
			margin-bottom: 5px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			min-height: 50px;
			border-bottom: 1px solid $pale-grey-two;
		}
	}

	img {
		margin-bottom: $grid-gutter-width;
		max-width: 100%;

		&[style*=left], &.float-left {
			margin-right: $grid-gutter-width;
		}

		&[style*=right], &.float-right {
			margin-left: $grid-gutter-width;
		}
	}

	@include media-breakpoint-down(md) {
		img {
			width: 100%;
		}
	}
}

@mixin shortText($font-size, $line-height, $rows) {
	height: $font-size * $line-height * $rows;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: $rows;
	-webkit-box-orient: vertical;
}

@mixin centerImage($width: '', $height: '') {
	@if $width {
		width: $width;
	}
	@if $height {
		height: $height;
	}
	object-fit: cover;
	object-position: center;
}

@mixin placeholder {
	&::-webkit-input-placeholder {
		@content
	}
	&:-moz-placeholder {
		@content
	}
	&::-moz-placeholder {
		@content
	}
	&:-ms-input-placeholder {
		@content
	}
}
