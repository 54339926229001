section.site-cart {
    background: #fff;
    padding: 20px 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    box-shadow: 0 -7px 14px 0 rgba(0, 0, 0, 0.03), 0 1px 0 0 $pale-grey-four;
    transition: all ease 0.3s;
    transform: translate(0, 100%);

    .show-btn {
        position: absolute;
        top: -44px;
        left: 50%;
        transform: translate(-50%, 0);
        background: #fff;
        border: none;
        @include border-top-radius(50px);
        color: $dark-slate-blue-two;
    }

    .item-info {
        display: flex;
        align-items: center;

        i {
            font-size: 17px;
            color: $dark-slate-blue-two;
            padding: 10px 0;
            cursor: pointer;
            margin-right: $grid-gutter-width;
        }

        span {
            font-size: 14px;
            font-weight: 400;
            color: $dark-slate-blue-two;
        }
    }

    .item-products {
        display: flex;
        align-items: center;

        div.item-product {
            background: $pale-grey-two;
            position: relative;
            margin-left: 10px;
            width: 80px;
            height: 80px;

            div.item-product-image {
                position: absolute;
                width: 100%;
                height: 100%;
                background-size: cover;

                .item-product-piece {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: 0;
                    background-color: $dark-slate-blue-two;
                    color: #eeeeee;
                    width: 25px;
                    height: 25px;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 25px;
                    text-align: center;
                    box-shadow: 0 0 1px 1px #999;
                    text-shadow: 0 1px 2px #333;
                }

                [data-button="remove-product"] {
                    position: absolute;
                    right: 0;
                    top: 0;
                    color: #fff;
                    cursor: pointer;
                    opacity: 0;
                    transition: opacity 0.5s;
                    -moz-transition: opacity 0.5s;
                    -webkit-transition: opacity 0.5s;
                    background: $danger;
                    font-size: 9px;
                    padding: 6px;
                }

                &:hover {
                    [data-button="remove-product"] {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .item-button {
        .btn {
            min-width: 160px;

            & + .btn {
                margin-left: 20px;
            }
        }
    }

    &.active {
        transform: translate(0, 0);

        @include media-breakpoint-down(md) {
            .show-btn {
                i {
                    transform: rotate(180deg);
                }
            }
        }
    }
}
