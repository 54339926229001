@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/bourbon/core/bourbon";
@import "../../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";
// @import "../../../node_modules/animatewithsass/animate.scss";
// @import "../../../node_modules/font-awesome/scss/font-awesome.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular.scss";
@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "../../../node_modules/plyr/src/sass/plyr.scss";
