.dropdown-toggle {
    &:after {
        @include triangle(down, 8px, 5px, $dark-slate-blue-two);
    }
}

.dropdown-menu {
    padding: 10px;
}

.dropdown-item {
    font-size: 16px;
    padding: 10px 14px;
    outline: none $i;
    font-weight: 400;

    &:hover, &:focus {
        background: $pale-grey-two;
        color: $dark-slate-blue-two;
    }
}
