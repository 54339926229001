.guide-list {
    &__item {
        background: $pale-grey-two;
        padding: 40px;
        margin-bottom: 20px;
    }

    &__title {
        font-size: 20px;
        font-weight: 500;
        color: $dark-slate-blue-two;
        margin-bottom: 20px;
    }

    &__description {
        font-size: 13px;
        line-height: 1.6;
        font-weight: 500;
        color: $dark-slate-blue-two;

        p {
            line-height: 1.6;
            margin-bottom: 20px;
        }
    }

    &__btn {
        margin-top: 20px;
        min-width: 185px;
    }

    @include media-breakpoint-down(sm) {
        &__item {
            padding: 30px;
        }
    }
}
