
.page-breadcrumb {
	position: relative;
	z-index: 5;
	margin-top: -15px;

	&__link {
		font-size: 14px;
		font-weight: 400;
		color: $dark-slate-blue-two;

		&:after {
			content: '/';
			display: inline-block;
			margin: 0 5px;
		}

		&:last-child {
			font-weight: 600;

			&:after {
				display: none;
			}
		}

		&:hover {
			color: $dark-slate-blue-two;
		}
	}

	@include media-breakpoint-down(md) {
		margin: 0;
		padding: 20px 0;
	}
}

.page-head {
	background: $azul;
	color: #fff;
	padding: 75px 0;
	margin-bottom: 40px;
	overflow: hidden;

	&__title {
		font-size: 30px;
		font-weight: 500;
		margin-bottom: 0;
	}

	&__sub-title {
		font-size: 20px;
		font-weight: 500;
		margin-top: 20px;
	}

	&__description {
		font-size: 13px;
		font-weight: 400;
		margin-top: 5px;
		margin-bottom: 0;
	}

	&__image {
		position: absolute;
		top: 50%;
		right: $grid-gutter-width / 2;
		transform: translate(0, -50%);
	}

	&__btn {
		min-width: 180px;
	}

	&--dark {
		margin-left: 4vh;
		margin-right: 4vh;
		color: #fff;
	}

	@include media-breakpoint-down(sm) {
		margin-bottom: 30px;
		padding: 30px 0;

		&__title {
			font-size: 26px;
			text-align: center;
		}

		&__description {
			text-align: center;
		}

		&--dark {
			margin-left: -15px;
			margin-right: -15px;
		}
	}

	@include media-breakpoint-down(md) {
		&__image {
			display: none;
		}
	}
}

.page-content {
	margin: 40px 0;

	@include media-breakpoint-down(md) {
		margin: 30px 0;
		margin-top: 0;
	}
}

.page-search {
	position: relative;

	&__input {
		height: 60px;
		font-size: 13px;
	}

	&__btn {
		position: absolute;
		top: 0;
		right: 0;
		height: 60px;
	}

	&__btn-icon {
		font-size: 14px;
	}

	@include media-breakpoint-down(sm) {
		margin-top: 30px;
	}
}

.page-share {
	&__label {
		font-size: 13px;
		font-weight: 400;
		margin-right: 10px;
	}

	&__list {
	}

	&__link {
		color: #fff;
		width: 28px;
		height: 28px;
		border-radius: 3px;
		transition: all ease 0.3s;
		margin: 0 2px;

		&:hover {
			background: #fff;
			color: $azul;
		}
	}

	&__icon {
		font-size: 16px;
		line-height: 16px;
	}

	@include media-breakpoint-down(sm) {
		justify-content: center;
	}
}

.page-author {
	&__image {
	}

	&__name {
		font-size: 13px;
		font-weight: 400;
	}

	@include media-breakpoint-down(sm) {
		justify-content: center;
		margin-top: 20px;
	}
}

.page-article {
	@include article();
}

.page-form {
	&__title {
		font-size: 28px;
		font-weight: 600;
		margin-bottom: 30px;
	}

	&__input {
	}

	&__btn {
	}
}

.page-btn-group {

	background: $pale-grey-two;
	border: 1px solid $pale-grey-four;
	border-radius: 5px;
	overflow: hidden;

	&__btn {
		font-size: 14px;
		font-weight: 500;
		color: fade_out($black-two, 0.5);

		&:hover {
			color: $black-two;
		}

		&--active {
			background: #fff;
			color: $black-two;
			border: 1px solid $pale-grey-four;
			margin: -1px;
		}
	}

	@include media-breakpoint-down(md) {
		&__btn {
			font-size: 12px;
			line-height: 1.2;
			text-align: left;
		}
	}

	@include media-breakpoint-down(sm) {
		display: block;

		&__btn {
			width: 100%;
			border-right: 0;
			border-bottom: 1px solid $pale-grey-four;
			text-align: center;

			&:last-child {
				border: none;
			}
		}
	}
}

article {
	@include article();
}
