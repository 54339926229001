.home-zigzag {
	&__item {
		width: 100%;
		margin: 0;
		margin-right: 30px;
		color: $dark-slate-blue-two;
	}

	&__title {
		font-size: 32px;
		line-height: 1.2;
		font-weight: 500;
	}

	&__description {
		margin-top: 10px;

		p {
			font-size: 14px;
			line-height: 1.6;
			font-weight: 400;
			margin-bottom: 0;
		}
	}

	&__image {
	}

	&__btn {
		margin-top: 35px;
	}

	@include media-breakpoint-down(md) {

		&__item {
			width: auto;
			margin-left: ($grid-gutter-width / 2) * -1;
			margin-right: ($grid-gutter-width / 2) * -1;
			margin-bottom: 30px;
		}

		&__image {
			margin-top: 30px;
		}
	}

	&--carousel {
		@include media-breakpoint-down(md) {
			.home-zigzag__item {
				width: 100%;
				margin-right: 30px;
			}

			.home-zigzag__image {
				margin-top: 0;
			}
		}
	}
}

.home-promo {
	padding-top: $header-height;
	padding-bottom: $header-height / 2;

	&__title {
		font-size: 36px;
		line-height: 1.2;
		font-weight: 500;
		color: #fff;
		transform: translate(0, -30px);
		opacity: 0;
		transition: all ease 0.3s;
	}

	&__btn {
		margin-top: 35px;
		transform: translate(0, -30px);
		opacity: 0;
		transition: all ease 0.3s;
	}

	&__image {
		width: 100%;
		transform: translate(0, 30px);
		opacity: 0;
		transition: all ease 0.3s;
	}

	&.is-selected {
		.home-promo__title {
			opacity: 1;
			transform: translate(0, 0);
		}

		.home-promo__btn {
			opacity: 1;
			transform: translate(0, 0);
		}

		.home-promo__image {
			opacity: 1;
			transform: translate(0, 0);
		}
	}

	@include media-breakpoint-down(md) {

		&__title {
			font-size: 28px;
		}

		&__image {
			height: auto;
		}
	}
}